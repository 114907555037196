<template>
  <div
    class="card mb-4"
    v-if="
      this.$store.state.getMovie.status.tvShowLoading &&
      this.$store.state.getMovie.status.seasonShowLoading
    "
  >
    <div class="card-header bg-success">
      <h3 class="text-center text-white my-2">
        قسمت های فصل {{ season.season_number }} برای {{ tv.name }}
      </h3>
      <p class="text-center text-white m-0">
        همه قسمت های این فصل نمایش شبکه تلوزیونی را در زیر مشاهده نمایید.
      </p>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12" v-if="season.overview">
          <p class="text-center" dir="ltr">
            <strong>overview: </strong>{{ season.overview }}
          </p>
        </div>
        <div
          class="col-sm-6 col-md-4 col-lg-3"
          v-for="episode in season.episodes"
          :key="episode.id"
        >
          <cardViewTvEpisode
            :episode="episode"
            :tvID="tv.id"
            :size="imageSizeMovie('w342')"
          ></cardViewTvEpisode>
        </div>
      </div>
    </div>
  </div>
  <div class="card" v-else>
    <spinner />
  </div>
</template>

<script>
import cardViewTvEpisode from "@/components/cardView/cardViewTvEpisode";
import Spinner from "@/components/template/spinner";
export default {
  name: "EpisodeTV",
  components: {
    Spinner,
    cardViewTvEpisode,
  },
  props: {
    tv: {
      type: Object,
      required: true,
    },
    season: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
