<template>
  <div class="card-view my-2">
    <div class="card-header">
      <router-link
        :to="{
          name: 'SingleEpisode',
          params: {
            id: tvID,
            season: episode.season_number,
            episode: episode.episode_number,
          },
        }"
      >
        <img
          v-if="episode.still_path"
          class="img-fluid w-100"
          :src="size + episode.still_path"
          :title="episode.name"
          :alt="episode.name"
        />
        <img
          v-else
          class="img-fluid w-100"
          src="../../assets/no_image_episode.jpg"
          :title="episode.name"
          :alt="episode.name"
        />
      </router-link>
    </div>
    <div class="card-movie-content">
      <div class="card-movie-content-head">
        <router-link
          :to="{
            name: 'SingleEpisode',
            params: {
              id: tvID,
              season: episode.season_number,
              episode: episode.episode_number,
            },
          }"
          class="d-block text-center"
        >
          <div>قسمت {{ episode.episode_number }}</div>
          <div v-text="episode.name"></div>
        </router-link>
        <div class="ratings">
          <span v-text="episode.vote_average"></span>/10
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cardViewTvEpisode",
  props: {
    episode: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
    tvID: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped></style>
